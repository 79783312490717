<template>
    <div>
        <label v-if="side == null ||side.toUpperCase() == 'RIGHT'" class="yds-color-secondary form-control-label yds-form-label pr-8"
               :for="getId">
            {{label}}
            <span v-if="_isRequired" class="yds-required-field">*</span>
        </label>
        <label class="switch vertical-align-middle">
            <input :name="name"
                   type="checkbox"
                   @change="onChange($event)"
                   :id="getId"
                   value="true"
                   v-model="_isChecked"
                   :checked="_isChecked"
                   v-bind="$attrs"
                   :tabindex="tabindex"/>
            <div class="slider round"></div>
        </label>
        <label v-if="side?.toUpperCase() == 'LEFT'" class="yds-color-secondary form-control-label yds-form-label pl-8"
               :for="getId">
            {{label}}
            <span v-if="_isRequired" class="yds-required-field">*</span>
        </label>
    </div>
</template>
<script>
    import { guidHelper, guidHelperFormats } from '/wwwroot/js/common/guidHelper.js'
    export default {
        name: "v_switch",
        emits: ["update:model-value"],
        props: {
            id: null,
            label: null,
            modelValue: null,
            name: null,
            required: null,
            tabindex: 0,
            side: { type: String, default: "right" }
        },
        data: () => ({
            _id: null,
            _isRequired: false,
            _isChecked: false
        }),
        methods: {
            onChange: function (event) {
                this.$nextTick(() => {
                    this.$emit("update:model-value", this._isChecked);
                });
            }
        },
        computed: {
            getId: {
                get: function () {
                    this._isRequired = this.required !== undefined;
                    if (this._id === null) {
                        this._id = guidHelper.getNewGuid("INPUT_", guidHelperFormats.short);
                    }
                    return this._id;
                }
            },
            currentValue: {
                get: function () {
                    return this.modelValue;
                },
                set: function (value) {
                    if (value === this.modelValue) {
                        return;
                    }
                    this.$emit("update:model-value", value);
                }
            }
        },
        watch: {
            modelValue(newValue, oldValue) {
                this._isChecked = this.modelValue;
            }
        },
        mounted: function () {
            this._isChecked = this.modelValue;
        }
    }
</script>
