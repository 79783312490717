<template id="ContactCheckout">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <v-textbox label="Search Phrase" v-model="searchPhrase" v-on:keyup.enter="performCheckoutSearch"></v-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-12 yds-cell-right">
                <v-button class="yds-w-150px" v-on:click="performCheckoutSearch">
                    Search
                </v-button>
            </div>
        </div>
        <div calss="row">
            <div class="mt-2 mb-2 col-12 yds-cell-right">
                <v-textbox type="date" label="Effective Date:" v-model="effectiveDate"></v-textbox>
            </div>
        </div>
        <div class="row mb-2">
            <v-grid :column-names="searchResultColumnNames"
                    :rows="searchResultRows"
                    filter-on-all-fields="true">
                <template v-slot:row="{row, rowNum}">
                    <td>{{row.vendorName}}</td>
                    <td>{{row.itemName}}</td>
                    <td>{{row.patternName}}</td>
                    <td>{{row.patternNumber}}</td>
                    <td>{{row.color}}</td>
                    <td>{{row.itemTypeName}}</td>
                    <td>
                        <v-button class="yds-button-no-border yds-button-no-background fa-solid fa-arrow-up-right-from-square yds-color-black"
                                  v-on:click="selectItemForCheckout(row)"></v-button>
                    </td>
                </template>
            </v-grid>
        </div>
        <div class="row">
            <div class="col yds-cell-right">
                <v-button v-if="searchResultRows.length == 0"
                          v-on:click="addAndCheckout">
                    Add &amp; Checkout
                </v-button>
            </div>
        </div>

        <edit-item-modal v-model:global-config-view-model="globalConfigViewModel"
                         v-model:message-box-model="messageBoxModel"
                         v-model:item-model="itemModel"
                         @client-item-image-url="clientItemImageUrl"
                         :client-id="clientId"></edit-item-modal>

        <v-api url="/Item/ItemSearch"
               :api-trigger="itemSearchTrigger"
               :api-body="itemSearchBody"
               v-on:api-response="itemSearchResponse"
               v-on:api-error="itemSearchError" />

        <v-api url="/Checkout/CheckOutItem"
               :api-trigger="checkoutTrigger"
               :api-body="checkoutBody"
               v-on:api-response="checkoutResponse"
               v-on:api-error="checkoutError" />
    </div>
</template>
<script>
    import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

    const ContactCheckout = {
        props: {
            clientId: 0,
            globalConfigViewModel: null,
            messageBoxModel: null,
            recordIsReadOnly: false,
            editContactModel: null,
            loadTrigger: 0,
            currentDate: null,
            emptyItemModel: null,
        },
        data: () => ({
            clientItemImageUrl: null,
            effectiveDate: null,
            checkoutTrigger: 0,
            checkoutBody: null,
            itemSearchTrigger: 0,
            itemSearchBody: null,
            searchPhrase: null,
            itemModel: null,
            searchResultColumnNames: [
                {
                    name: "Vendor",
                    columnName: "vendorName"
                },
                {
                    name: "Item Name",
                    columnName: "itemName"
                },
                {
                    name: "Pattern Name",
                    columnName: "patternName"
                },
                {
                    name: "Pattern #",
                    columnName: "patternNumber"
                },
                {
                    name: "Color Name",
                    columnName: "color"
                },
                {
                    name: "Item Type",
                    columnName: "itemTypeName"
                },
                {
                    // Link button
                    width: "2.5%"
                }
            ],
            searchResultRows: [],
            selectedItemForCheckout: null,
            checkingItemIdOut: 0
        }),
        methods: {
            performCheckoutSearch() {
                event.stopPropagation(); // Debounce

                this.itemSearchBody = {
                    clientId: this.editContactModel.clientId,
                    searchInput: this.searchPhrase
                };
                this.itemSearchTrigger++;
            },
            itemSearchResponse(response) {
                let possibleItemsToCheckOut = response.data.searchResults.filter(r => (r.numberOfCheckouts ?? 0) < r.maxQuantity);
                this.searchResultRows.length = 0;
                this.searchResultRows.push(...possibleItemsToCheckOut);
            },
            itemSearchError(error) {
            },
            selectItemForCheckout(row) {
                this.selectedItemForCheckout = row;
                MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                    body: "Are you sure you want to check this item out?",
                    title: "Attention",
                    middleButtonText: "No",
                    rightButtonText: "Yes",
                    rightButtonAction: () => this.performItemCheckout(row),
                    messageBoxType: MessageBoxType.question
                });
            },
            performItemCheckout(row) {
                this.checkingItemIdOut = row.id;
                this.checkoutBody = {
                    contactId: this.editContactModel.id,
                    itemId: row.id,
                    effectiveDate: this.effectiveDate
                };
                this.checkoutTrigger++;
            },
            checkoutResponse(response) {
                if (response.data.isSuccess) {
                    let filtered = this.searchResultRows.filter(r => r.id != this.checkingItemIdOut);
                    this.searchResultRows.length = 0;
                    this.searchResultRows.push(...filtered);
                }
            },
            checkoutError(error) {
            },
            addAndCheckout() {
                this.makeNewItemModel();
                this.globalItemEditViewModel.isEditItemVisible = true;
            }
        },
        watch: {
            itemModel(newValue, oldValue) {
                if (this.itemModel.id > 0) {
                    this.searchResultRows.push(this.itemModel);
                    this.performItemCheckout(this.itemModel);
                }
            },
        //    loadTrigger(newValue, oldValue) {
        //        this.effectiveDate = this.currentDate;
        //        this.resetView();
        //    }
        },
        template: "#ContactCheckout"
    };

    export default ContactCheckout;
</script>