<template>
    <div>
        <v-api url="/Log/Submit"
               :api-trigger="logTrigger"
               :api-body="message"
               doesNotTriggerSpinner="true"
               :is-logger=true>
        </v-api>
    </div>
</template>
<script>
    export default {
        data: () => ({
            message: null,
            logLevel: null,
            logTrigger: null,
            previousTrigger: null,
            parameters: []
        }),
        name: "v_logger",
        watch: {
            loggerConfig: {
                handler(newValue, oldValue) {
                    if (newValue.trigger != this.previousTrigger) {
                        let logLevel = newValue.logLevel;
                        if (logLevel >= this.globalConfig.minimumLogLevel) {
                            this.message = {
                                logLevel: logLevel,
                                message: newValue.message,
                                parameters: newValue.parameters
                            };
                            this.logTrigger++;
                        }
                        this.previousTrigger = newValue.trigger;
                    }
                },
                deep: true
            }
        }
    }
</script>