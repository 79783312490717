﻿const NotificationModal = {
    emits: ["update:notifications-are-visible"],
    props: {
        notificationsAreVisible: false,
        notifications: null,
        currentTimeZone: null,
        hourOffsetFromUtc: 0,
        sendingClients: null
    },
    data: () => ({
        notificationColumns: [
            {
                width: "50px",
                canSort: false
            },
            {
                name: "Subject",
                canSort: false
            },
            {
                name: "Date",
                width: "100px",
                canSort: false
            }
        ],
        notificationRows: [],
        selectedNotification: null,
        readNotificationTrigger: 0,
        readNotificationBody: null
    }),
    methods: {
        rowTrigger(row) {
            this.selectedNotification = row;
            this.readNotificationBody = {
                notificationId: row.id
            };
            this.readNotificationTrigger++;
        },
        formatDate(dateString) {
            if (dateString == null) {
                return;
            }

            let date = new Date(dateString);
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        },
        formatTime(dateString) {
            if (dateString == null) {
                return;
            }

            let date = new Date(dateString);
            let adjustedDate = new Date(date.getTime() - this.hourOffsetFromUtc * 3600000);

            let ampm = "AM";
            let hours = adjustedDate.getHours();
            if (hours > 12) {
                hours -= 12;
                ampm = "PM";
            }
            let minutes = (adjustedDate.getMinutes() < 10 ? '0' : '') + adjustedDate.getMinutes();
            return `${hours}:${minutes} ${ampm}`;
        },
        closeMessageButtonClick() {
            this.selectedNotification = null;
        },
        closeButtonClick() {
            this.$emit("update:notifications-are-visible", false);
        },
        getClientName() {
            if (this.sendingClients == null || this.selectedNotification == null) {
                return;
            }

            let client = this.sendingClients.filter(sc => sc.id == this.selectedNotification.clientId)[0];
            return client?.name;
        },
        readNotificationResponse(response) {
            this.selectedNotification.readOnUtc = new Date();
            this.sortNotifications();
        },
        sortNotifications() {
            let sorted = this.notifications.sort((left, right) => {
                let leftValue =
                    (left.readOnUtc == null ? " " : "X") +
                    left.createdOnUtc;
                let rightValue =
                    (right.readOnUtc == null ? " " : "X") +
                    right.createdOnUtc;
                return leftValue > rightValue ? -1 : 1;
            });
            this.notificationRows.length = 0;
            sorted.forEach(notification => {
                this.notificationRows.push(notification);
            });
        }
    },
    watch: {
        notificationsAreVisible(newValue, oldValue) {
            if (newValue == true) {
                this.sortNotifications();
            }
        }
    },
    template: "#NotificationModal"
};

export default NotificationModal
