const DashboardWithClient = {
    props: {
        clientId: 0,
        globalConfigViewModel: null,
        messageBoxModel: null,
        editContactModel: null
    },
    data: () => ({
        contactWithItemsCheckedOutOverNumberOfDaysTrigger: 0,
        contactWithItemsCheckedOutOverNumberOfDaysBody: null,
        vendorInterestOverNumberOfDaysTrigger: 0,
        vendorInterestOverNumberOfDaysBody: null,
        getDesignersWithFeeAssessmentsTrigger: 0,
        getDesignersWithFeeAssessmentsBody: null,
        moreThanSevenColumnNames: [
            {
                name: "Designer",
                columnName: "displayName"
            },
            {
                name: "Count",
                columnName: "quantity",
                width: "150px",
                isNumeric: "true"
            }
        ],
        moreThanSevenRows: [],
        interestOver14DaysColumnNames: [
            {
                name: "Vendor",
                columnName: "vendorName"
            },
            {
                name: "Count",
                width: "150px",
                columnName: "totalCount",
                isNumeric: "true"
            }
        ],
        interestOver14DaysRows: [],
        designersWithFeeAssessmentColumnNames: [
            {
                name: "Designer",
                columnName: "displayName"
            },
            {
                name: "Amount",
                width: "150px",
                columnName: "currentFeeAssessment",
                isNumeric: "true"
            }
        ],
        designersWithFeeAssessmentRows: []
    }),
    methods: {
        getCurrentFeeAssessment: function (currentFeeAssessment) {
            if (currentFeeAssessment == null) {
                currentFeeAssessment = 0;
            }
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            let formatted = formatter.format(currentFeeAssessment);
            return formatted;
        },
        editContact: function (contactId, dataSource) {
            let contact;
            switch (dataSource) {
                case 0: contact = this.moreThanSevenRows.filter(row => row.id === contactId);
                    break;

                case 1: contact = this.interestOver14DaysRows.filter(row => row.id === contactId);
                    break;

                case 2: contact = this.designersWithFeeAssessmentRows.filter(row => row.id === contactId);
                    break;
            }

            this.$emit("update:edit-contact-model", contact[0]);

            this.globalConfigViewModel.isEditContactReadOnly = true;
            this.globalContactEditViewModel.isEditContactVisible = true;

            this.$nextTick(() => this.globalConfigViewModel.trigger++);
        },
        contactWithItemsCheckedOutOverNumberOfDaysResponse(response) {
            this.moreThanSevenRows.length = 0;
            if (response.data.data.length > 0) {
                this.moreThanSevenRows.push(...response.data.data);
            }
        },
        vendorInterestOverNumberOfDaysResponse(response) {
            this.interestOver14DaysRows.length = 0;
            if (response.data.data.summaryEntries.length > 0) {
                this.interestOver14DaysRows.push(...response.data.data.summaryEntries);
            }
        },
        getDesignersWithFeeAssessmentsResponse(response) {
            this.designersWithFeeAssessmentRows.length = 0;
            if (response.data.data.entries.length > 0) {
                this.designersWithFeeAssessmentRows.push(...response.data.data.entries);
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.contactWithItemsCheckedOutOverNumberOfDaysBody = {
                checkedOutOverNumberOfDays: 7
            };
            this.contactWithItemsCheckedOutOverNumberOfDaysTrigger++;

            this.vendorInterestOverNumberOfDaysBody = {
                overNumberOfDays: 140
            };
            this.vendorInterestOverNumberOfDaysTrigger++;

            this.getDesignersWithFeeAssessmentsTrigger++;
        });
    },
    watch: {
        globalContactEditViewModel: {
            handler(value) {
                if (this.globalContactEditViewModel.editContactModelUpdated == true) {
                    this.editContactModelUpdated();
                }

                if (this.globalVendorEditViewModel.editVendorModelUpdated == true) {
                    this.editVendorModelUpdated();
                }
            },
            deep: true
        }
    },
    template: "#DashboardWithClient"
};

export default DashboardWithClient;
