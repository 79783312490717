import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

const AccountSecurity = {
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        userModel: null,
        loadTrigger: 0
    },
    data: () => ({
        currentPassword: null,
        newPassword: null,
        passwordConfirmation: null,
        updatePasswordBody: null,
        updatePasswordTrigger: 0
    }),
    methods: {
        saveClick() {
            this.updatePasswordBody = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
                newPasswordConfirmation: this.passwordConfirmation
            };
            this.updatePasswordTrigger++;
        },
        updatePasswordResponse(response) {
            switch (response.data.responseHeader.statusCode) {
                case StatusCodes.NoError:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "Your password has been updated.",
                        messageBoxType: MessageBoxType.information
                    });
                    this.currentPassword = null;
                    this.newPassword = null;
                    this.passwordConfirmation = null;
                    break;

                case StatusCodes.BadParametersPassed:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "There was an error in your request.  Please re-check the entered password fields.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;

                default:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "An unknown error occurred.  Please try again in a few minutes.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;
            }
        }
    },
    template: "#AccountSecurity"
}

export default AccountSecurity
