const ValidationMixin = {
    methods: {
        setValidationNeeded() {
            this.validationMessages = [];
            this.validationNeeded = true;
            this.$nextTick(this.setValidationNeededCompleted);

        },
        setValidationNeededCompleted() {
            this.validationNeeded = false;
            if (this.getNumberOfValidationMessages() > 0) {
                this.validationUnsuccessful();
            } else {
                this.validationSuccessful();
            }
        },
        clearValidationMessages() {
            window.dispatchEvent(new CustomEvent("clearValidationMessages"));
        },
        validationSuccessful() {
        },
        validationUnsuccessful() {
        },
        getNumberOfValidationMessages() {
            let numberOfMessages = 0;

            for (const [key, value] of Object.entries(this.validationMessages)) {
                numberOfMessages += value.length;
            }

            return numberOfMessages;
        },
        getValidationMessages() {
            return this.validationMessages;
        }
    }
};

export default ValidationMixin;
