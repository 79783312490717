<!--
DOCUMENTED in TRQ-410
-->
<template>
    <div class="yds-text-editor-container">
        <div v-show="isToolbarHidden" class="yds-message-editor-tool-bar-mask"></div>
        <div class="yds-text-editor" ref="quillEditor"></div>
    </div>
</template>
<script>
    import { guidHelper, guidHelperFormats } from '/wwwroot/js/common/guidHelper.js'

    export default {
        emits: ["editor-set-body-response", "editor-get-body-response"],
        name: "v_editor",
        props: {
            editorSetBody: 0,
            editorBody: null,
            editorGetBody: 0.,
            isReadOnly: false,
            isToolbarHidden: false
        },
        data: () => ({
            quill: null
        }),
        methods: {
            setReadOnly() {
                this.quill.enable(!this.isReadOnly);
            },
            setHideToolbar() {
                document.getElementsByClassName('ql-toolbar')[0].style.display = this.isToolbarHidden ? 'none' : '';
            }
        },
        watch: {
            editorSetBody(newValue, oldValue) {
                this.quill.root.innerHTML = this.editorBody;
                this.$emit("editor-set-body-response", "success");
            },
            editorGetBody(newValue, oldValue) {
                let body = this.quill.getSemanticHTML();
                this.$emit("editor-get-body-response", body);
            },
            isReadOnly(newValue, oldValue) {
                this.setReadOnly();
            },
            isToolbarHidden(newValue, oldValue) {
                this.setHideToolbar();
            }
        },
        mounted() {
            /*
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                ['link', 'image', 'video', 'formula'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
             */
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                ['link', 'image'],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];

            this.quill = new Quill(this.$refs.quillEditor, {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions
                }
            });

            this.setReadOnly();
            this.setHideToolbar();
        }
    }
</script>