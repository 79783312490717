export default {
    processValidators(caller, value, validators, modelContainer, modelName) {
        if (value == null) {
            value = "";
        }

        let validationMessages = [];
        let hasRequiredValidator = validators.filter(v => v.Name == "required").length > 0;

        for (const validator of validators) {
            let errorFound = false;
            switch (validator.Name) {
                case "maxlength":
                    {
                        errorFound = value.length > validator.Arguments[0];
                    }
                    break;

                case "minlength":
                    {
                        errorFound = value.length < validator.Arguments[0];
                    }
                    break;

                case "regex":
                    {
                        let regex = new RegExp(validator.Arguments[0]);
                        errorFound = value.length > 0 && !regex.test(value);
                    }
                    break;

                case "required":
                    {
                        let v;
                        if (modelContainer != null) {
                            v = modelContainer[modelName];
                        } else {
                            v = value;
                        }
                        errorFound = v == undefined || v == null || v == "";
                    }
                    break;

                case "reqoneof":
                    {
                        let valuesFound = 0;
                        if (modelContainer == null) {
                            for (const otherPropertyName in validator.Arguments) {
                                let otherValue = caller[otherPropertyName];
                                if (otherValue == undefined) {
                                    otherPropertyName = otherPropertyName.charAt(0).toLowerCase() + otherPropertyName.slice(1);
                                    otherValue = caller[otherPropertyName];
                                }

                                if (value != "") {
                                    valuesFound++;
                                }
                            }
                        } else {
                            for (const index in validator.Arguments) {
                                let lowerCaseArgument = validator.Arguments[index].toLowerCase();
                                let value = modelContainer[Object.keys(modelContainer).find(key => key.toLowerCase() === lowerCaseArgument)] ?? "";

                                if (value != "") {
                                    valuesFound++;
                                }
                            }
                        }
                        errorFound = valuesFound == 0;
                    }
                    break;

                case "mustmatchvalue":
                    {
                        let otherPropertyName = validator.Arguments[0];
                        let otherValue;

                        if (modelContainer == null) {
                            otherValue = caller[otherPropertyName];
                            if (otherValue == undefined) {
                                otherPropertyName = otherPropertyName.charAt(0).toLowerCase() + otherPropertyName.slice(1);
                                otherValue = caller[otherPropertyName];
                            }
                        } else {
                            let lowerOtherPropertyName = otherPropertyName.toLowerCase();
                            otherValue = modelContainer[Object.keys(modelContainer).find(key => key.toLowerCase() === lowerOtherPropertyName)] ?? "";
                        }

                        errorFound = value != otherValue;
                    }
                    break;

                case "datemustoccurafter":
                    {
                        let otherPropertyName = validator.Arguments[0];
                        let otherValue;
                        if (modelContainer == null) {
                            otherValue = caller[otherPropertyName];
                            if (otherValue == undefined) {
                                otherPropertyName = otherPropertyName.charAt(0).toLowerCase() + otherPropertyName.slice(1);
                                otherValue = caller[otherPropertyName];
                            }
                        } else {
                            let lowerOtherPropertyName = otherPropertyName.toLowerCase();
                            otherValue = modelContainer[Object.keys(modelContainer).find(key => key.toLowerCare() == lowerOtherPropertyName)] ?? "";
                        }
                    }
                    break;

                case "datemustoccurbefore":
                    {
                        let otherPropertyName = validator.Arguments[0];
                        let otherValue;
                        if (modelContainer == null) {
                            otherValue = caller[otherPropertyName];
                            if (otherValue == undefined) {
                                otherPropertyName = otherPropertyName.charAt(0).toLowerCase() + otherPropertyName.slice(1);
                                otherValue = caller[otherPropertyName];
                            }
                        } else {
                            let lowerOtherPropertyName = otherPropertyName.toLowerCase();
                            otherValue = modelContainer[Object.keys(modelContainer).find(key => key.toLowerCare() == lowerOtherPropertyName)] ?? "";
                        }
                    }
                    break;

                default:
                    break;
            }

            if (errorFound) {
                validationMessages.push(validator.ErrorMessage);
            }
        }

        return validationMessages;
    }
};
