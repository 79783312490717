import { NavBarElementType } from "/wwwroot/js/common/navBarElementType.js";

const NavBarElementClientSelector = {
    props: {
        modelValue: null,
        globalConfigViewModel: null
    },
    data: () => ({
        clientSelectorCollection: null
    }),
    methods: {
        updateClientCollection() {
            let collection = [
                {
                    title: "Change active client to:",
                    typeOfElement: NavBarElementType.Label
                }
            ];
            for (var idx in this.globalConfigViewModel.clientModels) {
                let client = this.globalConfigViewModel.clientModels[idx];
                let child = {
                    title: client.name,
                    typeOfElement: NavBarElementType.DropdownItem,
                    href: "/ClientConfiguration/Change?i=" + client.id
                };
                collection.push(child);
            }
            this.clientSelectorCollection.children.length = 0;
            this.clientSelectorCollection.children.push(...collection);
        }
    },
    created() {
        this.clientSelectorCollection = {
            title: this.globalConfigViewModel.clientName,
            typeOfElement: NavBarElementType.Dropdown,
            children: []
        };
        this.updateClientCollection();
    },
    watch: {
        globalConfigViewModel: {
            handler(newValue, oldValue) {
                this.updateClientCollection();
            },
            deep: true
        }
    },
    template: "#NavBarElementClientSelector"
}

export default NavBarElementClientSelector
