<template>
</template>
<script>
    import { LogLevel } from '/wwwroot/js/common/logger.js'

    export default {
        emits: ["api-response", "api-error"],
        props: {
            url: null,
            apiBody: null,
            apiTrigger: 0,
            verb: { type: String, default: "POST" },
            doesNotTriggerSpinner: null,
            isLogger: false
        },
        watch: {
            apiTrigger(newValue, oldValue) {
                if (!this.isLogger && this.loggerConfig != null) {
                    this.loggerConfig.submit(LogLevel.Debug, `Attempting to initiate ${this.verb} to ${this.url}, sending body of:`, this.apiBody);
                }
                if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                    window.dispatchEvent(new CustomEvent("addAjaxCall"));
                }

                axios({
                    method: this.verb,
                    url: this.url,
                    data: JSON.stringify(this.apiBody),
                    headers: {
                        "content-type": "application/json"
                    }
                })
                    .then(response => {
                        if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                            window.dispatchEvent(new CustomEvent("removeAjaxCall"));
                        }
                        if (!this.isLogger && this.loggerConfig != null) {
                            this.loggerConfig.submit(LogLevel.Debug, "RESPONSE", response);
                        }
                        this.$emit("api-response", response);
                    })
                    .catch(error => {
                        if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                            window.dispatchEvent(new CustomEvent("removeAjaxCall"));
                        }
                        if (!this.isLogger && this.loggerConfig != null) {
                            console.log(this.loggerConfig);
                            this.loggerConfig.submit(LogLevel.Error, "ERROR", error);
                        }
                        this.$emit("api-error", error);
                    });
            }
        },
        name: "v_api"
    };
</script>
