import v_api from './v-api.vue';
import v_button from './v-button.vue';
import v_checkbox from './v-checkbox.vue';
import v_editor from './v-editor.vue';
import v_logger from './v-logger.vue';
import v_dropdown from './v-dropdown.vue';
import v_grid from './v-grid.vue';
import v_grid_num_row_selector from './v-grid-num-row-selector.vue';
import v_grid_pagination from './v-grid-pagination.vue';
import v_help_topic from './v-help-topic.vue';
import v_switch from './v-switch.vue';
import v_tablist from './v-tablist.vue';
import v_textarea from './v-textarea.vue';
import v_textbox from './v-textbox.vue';
import v_validator from './v-validator.vue';

import contact_checkout from '/Components/UI/Secured/Traq/Contact/contact_checkout.vue';
import contact_current from '/Components/UI/Secured/Traq/Contact/contact_current.vue';
import contact_history from '/Components/UI/Secured/Traq/Contact/contact_history.vue';

//import vendor_checkout from '/Components/UI/Secured/Traq/Vendor/vendor_checkout.vue';
//import vendor_current from  '/Components/UI/Secured/Traq/Vendor/vendor_current.vue';
//import vendor_history from  '/Components/UI/Secured/Traq/Vendor/vendor_history.vue';

export default {
    v_api,
    v_button,
    v_checkbox,
    v_editor,
    v_logger,
    v_dropdown,
    v_switch,
    v_textarea,
    v_textbox,
    v_grid,
    v_grid_num_row_selector,
    v_grid_pagination,
    v_help_topic,
    v_tablist,
    v_validator,

    contact_checkout,
    contact_current,
    contact_history,

    //vendor_checkout,
    //vendor_current,
    //vendor_history,

    register: function (app, componentList) {
        if (app == null) {
            return;
        }

        let componentNames = componentList == null ? Object.getOwnPropertyNames(this) : Object.getOwnPropertyNames(componentList);
        for (let k = 0; k < componentNames.length; k++) {
            let propName = (Array.isArray(componentList) && !isNaN(componentNames[k])) ? componentList[k] : componentNames[k];
            if (propName == null || propName == "register" || this[propName] == null) {
                continue;
            }
            let regName = (componentList != null && !Array.isArray(componentList)) ? componentList[propName] : propName.replaceAll("_", "-");
            //console.log(`Registering: ${regName}`);
            app.component(regName, this[propName]);
        }
    }
}
