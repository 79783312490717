﻿/*
    /Views/Secured/Traq/Contact/contact_options.cshtml
 */

import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";
import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { PreferenceName } from "/wwwroot/js/common/preferenceName.js";

const ContactOptions = {
    emits: [],
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        editContactModel: null,
        recordIsReadOnly: false,
        stateListItemString: null,
        loadTrigger: 0
    },
    data: () => ({
        isEditContactReadOnly: false,
        apiBody: null,
        apiTrigger: 0,
        allowAutoEmails: true
    }),
    methods: {
        closeButtonClick() {
            this.globalContactEditViewModel.isEditContactVisible = false;
        },
        initialize() {
            this.isEditContactReadOnly = this.globalConfigViewModel.isEditContactReadOnly;
            this.pullEditContactModel();
        },
        pullEditContactModel() {
            this.allowAutoEmails = this.editContactModel?.preferences["ContactDoNotAutomatedEmail"] == "true" ? true : false;
        },
        pushEditContactMOdel() {
        },
        saveChanges() {
            this.apiBody = {
                contactId: this.editContactModel.id,
                preferenceNameId: PreferenceName.ContactDoNotAutomatedEmail,
                value: this.allowAutoEmails ? "true" : "false"
            };
            this.apiTrigger++;
        },
        contactUpdatePreferenceResponse(response) {
            this.globalContactEditViewModel.isEditContactVisible = false;
        }
    },
    watch: {
        globalContactEditViewModel: {
            handler(newValue, oldValue) {
                if (this.isEditContactVisible == true) {
                    this.initialize();
                }
            },
            deep: true
        }
    },
    created() {
        this.initialize();
    },
    template: "#ContactOptions"
};
export default ContactOptions;
